<template>
  <div class="about container" style="">
    <div class="quote-sent" v-if="quoteSent">Quote was sent.</div>
    <b-modal
      v-model="requirementModal"
      ref="requirement-modal"
      hide-footer
      title="Required Fields"
    >
      <div class="d-block text-left">
        <ul style="list-style-type: none">
          <li style="color: red" v-for="value in errors" v-bind:key="value.id">
            <h4>{{ value }}</h4>
          </li>
        </ul>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideRequirementModal"
        >Close</b-button
      >
    </b-modal>

    <b-modal
      v-model="requestQuoteModal"
      ref="request-quote-modal"
      hide-footer
      title="Request Quote"
    >
      <h5>Would you like to request a quote for this build?</h5>
      <b-button-group size="lg">
        <b-button
          class="mt-3"
          variant="danger"
          block
          @click="
            hideRequestQuoteModal();
            sendPDFOnly();
          "
          >No</b-button
        >
      </b-button-group>
      <b-button-group size="lg" class="float-right">
        <b-button class="mt-3" variant="success" block @click="showQuoteModal"
          >Yes</b-button
        >
      </b-button-group>
    </b-modal>

    <b-modal v-model="quoteModal" ref="quote-modal" hide-footer title="Quote">
      <h5>Please fill out this form to request a quote:</h5>
      <div>
        <b-form @submit.prevent="sendEmail" id="quote-form">
          <b-form-group id="input-group-1" label="Name:" label-for="input-1">
            <b-form-input
              id="input-1"
              placeholder="Enter Name"
              required
              v-model="quoteName"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="Company:" label-for="input-2">
            <b-form-input
              id="input-2"
              placeholder="Enter Company"
              required
              v-model="quoteCompany"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-3" label="City:" label-for="input-3">
            <b-form-input
              id="input-3"
              placeholder="Enter City"
              required
              v-model="quoteCity"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-4" label="State:" label-for="input-4">
            <b-form-input
              id="input-4"
              placeholder="Enter State"
              required
              v-model="quoteState"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-5" label="Phone:" label-for="input-5">
            <b-form-input
              id="input-5"
              placeholder="Enter Phone"
              required
              v-model="quotePhone"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-6"
            label="Email Address:"
            label-for="input-6"
          >
            <b-form-input
              id="input-6"
              placeholder="Enter Email"
              required
              v-model="quoteEmail"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-7"
            label="Building:"
            label-for="input-7"
          >
            <b-form-radio v-model="quoteBuilding" value="new">New</b-form-radio>
            <b-form-radio v-model="quoteBuilding" value="existing"
              >Existing</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-group-8"
            label="Runway Required:"
            label-for="input-8"
          >
            <b-form-radio v-model="quoteRunway" value="yes">Yes</b-form-radio>
            <b-form-radio v-model="quoteRunway" value="no">No</b-form-radio>
          </b-form-group>
          <b-form-group
            id="input-group-9"
            label="Runway Specifications (If Required):"
            label-for="input-9"
          >
            <b-form-textarea
              id="input-9"
              placeholder="Runway Specifications"
              v-model="quoteRunwaySpecifications"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
            id="input-group-10"
            label="Installation Required:"
            label-for="input-10"
          >
            <b-form-radio v-model="quoteInstall" value="yes">Yes</b-form-radio>
            <b-form-radio v-model="quoteInstall" value="no">No</b-form-radio>
          </b-form-group>
          <b-form-group
            id="input-group-11"
            label="Project Timeline:"
            label-for="input-11"
          >
            <b-form-radio value="budget" v-model="quoteTimeline"
              >Budget</b-form-radio
            >
            <b-form-radio value="immediate" v-model="quoteTimeline"
              >Immediate</b-form-radio
            >
            <b-form-radio value="Next 6 months" v-model="quoteTimeline"
              >Next 6 Months</b-form-radio
            >
            <b-form-radio value="Next 12 months" v-model="quoteTimeline"
              >Next 12 Months</b-form-radio
            >
          </b-form-group>
          <b-button @click="hideQuoteModal" variant="danger">Close</b-button>
          <b-button
            class="float-right"
            @click="
              showThankYouModal();
              sendPDFQuoteInfo();
            "
            variant="success"
            >Submit</b-button
          >
        </b-form>
      </div>
    </b-modal>

    <b-modal
      v-model="thankYouModal"
      ref="thank-you-modal"
      hide-footer
      title="Thank You"
    >
      <div class="d-block text-left">
        <h5>Thank you for your Quote Request.</h5>
        <h5>A Uesco Crane distributor will reach out to you shortly.</h5>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideThankYouModal"
        >Close</b-button
      >
    </b-modal>

    <div class="troubleshoot">{{ cranes[0] }}</div>
    <div class="troubleshoot">{{ hoists[0] }}</div>
    <!-- <button class="troubleshoot" v-on:click="filterCranesCapacitySpan">Filter Capacity Data</button>
    <div class="troubleshoot">{{cranesDataListCapacity}}</div>
    <button class="troubleshoot" v-on:click="filterCranesType">Filter Crae Data</button>
    <div class="troubleshoot">{{cranesDataListType}}</div> -->

    <h1>Single Girder Top Running Dual Motor </h1>
    <hr />
    <div class="row">
      <div
        class="col-2 text-left"
        v-bind:class="{ required: requiredCapacity }"
      >
        <strong>Capacity</strong> - Tons:
        <b-form-select
          @change="
            filterCranesType();
            filterCranesCapacitySpan();
            setHoistDropDown();
            setHoist();
          "
          v-model="capacity"
          :options="capacities"
          v-bind:class="{ requiredBorder: requiredCapacity }"
        ></b-form-select>
      </div>
      <div class="col-2 text-left" v-bind:class="{ required: requiredSpan }">
        <strong>Span</strong> - Feet:
        <b-form-input
          type="number"
          @change="
            checkSpanLimit();
            filterCranesType();
            filterCranesCapacitySpan();
          "
          v-model="spanFeet"
          placeholder="Feet"
          v-bind:class="{ requiredBorder: requiredSpan }"
        ></b-form-input>
      </div>
      <div class="col-2 text-left">
        Inch:
        <b-form-select
          @change="
            filterCranesType();
            filterCranesCapacitySpan();
            setHoist();
          "
          v-model="spanInch"
          :options="inches"
        ></b-form-select>
      </div>
      <div class="col-2 text-left">
        Fractions:
        <b-form-select
          @change="
            checkSpanLimit();
            filterCranesType();
            filterCranesCapacitySpan();
            setHoist();
          "
          v-model="spanFraction"
          :options="fractions"
        ></b-form-select>
      </div>
      <div class="col-2 text-left" v-bind:class="{ required: requiredLift }">
        <strong>Lift</strong> - Feet:
        <b-form-input
          @change="
            checkSpanLimit();
            setHoistDropDown();
            setHoist();
          "
          type="number"
          v-model="liftFeet"
          placeholder="Feet"
          v-bind:class="{ requiredBorder: requiredLift }"
        ></b-form-input>
      </div>
    </div>

    <hr />

    <div class="container m-20 crane">
      <!-- crane -->
      <div class="row text-left">
        <!-- direction label -->
        <strong>Direction</strong> - North
      </div>
      <!-- direction label -->
      <div class="row" style="padding-top: 125px">
        <!-- direction dropdown -->
        <div class="col-5"></div>
        <div class="col-2">
          <b-form-select
            v-model="direction"
            :options="directions"
          ></b-form-select>
        </div>
      </div>
      <!-- direction dropdown -->
      <hr />

      <div class="row">
        <!-- details label -->
        <strong>Details</strong>
      </div>

      <div class="row" style="padding-top: 80px">
        <!-- details rail & obstruction -->
        <div class="col-5" style="">
          <strong>Top of ASCE Rail</strong>
          <div class="container">
            <div class="row">
              <div class="col-4 text-left">
                Feet:
                <b-form-input
                  type="number"
                  v-model="asceRailFeet"
                  placeholder="Feet"
                ></b-form-input>
              </div>
              <div class="col-4 text-left">
                Inch:
                <b-form-select
                  v-model="asceRailInch"
                  :options="inches"
                ></b-form-select>
              </div>
              <div class="col-4 text-left">
                Fractions:
                <b-form-select
                  v-model="asceRailFraction"
                  :options="fractions"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5" style="">
          <strong>Lowest Obstruction Height</strong>
          <div class="container">
            <div class="row">
              <div class="col-4 text-left">
                Feet:
                <b-form-input
                  type="number"
                  v-model="obstructionFeet"
                  placeholder="Feet"
                ></b-form-input>
              </div>
              <div class="col-4 text-left">
                Inch:
                <b-form-select
                  v-model="obstructionInch"
                  :options="inches"
                ></b-form-select>
              </div>
              <div class="col-4 text-left">
                Fractions:
                <b-form-select
                  v-model="obstructionFraction"
                  :options="fractions"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- details rail & obstruction -->
    </div>
    <!-- crane -->

    <hr />

    <div class="container hoistSelection">
      <div class="row">
        <div
          class="col-3 text-left"
          v-bind:class="{ required: requiredHoistAmount }"
        >
          Hoist:
          <!-- Hoist: <strong>{{ hoistAmount }}</strong> -->
          <b-form-radio-group
            id="radio-group-hoist-amount"
            @change="setHoistDropDown"
            v-model="hoistAmount"
            :options="hoistAmounts"
            name="raido-hoistsoptions"
          ></b-form-radio-group>
        </div>
        <div
          class="col-3 text-left"
          v-bind:class="{ required: requiredHoistManufacturer }"
        >
          Manufacturer:
          <!-- Manufacturer: <strong>{{ hoistManufacturer }}</strong> -->
          <b-form-radio-group
            id="radio-group-hoist-manufacturer"
            @change="setHoist"
            v-model="hoistManufacturer"
            :options="hoistManufacturers"
          ></b-form-radio-group>
        </div>
        <!-- single -->
        <div
          class="col-2"
          v-show="hoistAmount == 'single' && hoistManufacturer"
        >
          <div v-bind:class="{ required: requiredHoistSingle }">
            Single: Capacity
            <b-form-select
              @change="setHoist"
              v-model="capacitySingle"
              :options="capacitiesLimit"
              v-bind:class="{ requiredBorder: requiredHoistSingle }"
            ></b-form-select>
          </div>
          <img
            v-show="hoistManufacturer == 'detroit'"
            alt="Vue logo"
            src="../assets/detroit.png"
          />
          <img
            v-show="hoistManufacturer == 'rm'"
            alt="Vue logo"
            src="../assets/rm.png"
          />
        </div>
        <!-- dual -->
        <div class="col-2" v-show="hoistAmount == 'dual' && hoistManufacturer">
          <div align-v="center" v-bind:class="{ required: requiredHoistDualA }">
            Dual A: Capacity
            <b-form-select
              @change="setHoist"
              v-model="capacityDualA"
              :options="capacitiesLimit"
              v-bind:class="{ requiredBorder: requiredHoistDualA }"
            ></b-form-select>
            <img
              v-show="hoistManufacturer == 'detroit'"
              alt="Vue logo"
              src="../assets/detroit.png"
            />
            <img
              v-show="hoistManufacturer == 'rm'"
              alt="Vue logo"
              src="../assets/rm.png"
            />
          </div>
        </div>
        <div class="col-2" v-show="hoistAmount == 'dual' && hoistManufacturer">
          <div align-v="center" v-bind:class="{ required: requiredHoistDualB }">
            Dual B: Capacity
            <b-form-select
              @change="setHoist"
              v-model="capacityDualB"
              :options="capacitiesLimit"
              v-bind:class="{ requiredBorder: requiredHoistDualB }"
            ></b-form-select>
            <img
              v-show="hoistManufacturer == 'detroit'"
              alt="Vue logo"
              src="../assets/detroit.png"
            />
            <img
              v-show="hoistManufacturer == 'rm'"
              alt="Vue logo"
              src="../assets/rm.png"
            />
          </div>
        </div>
      </div>

      <div
        class="row troubleshoot"
        v-show="hoistAmount == 'dual' && hoistManufacturer"
      >
        <div class="col-4">
          <!-- {{ hoistDualA }} -->
        </div>
        <div class="col-2" align-v="center"></div>
        <div class="col-4">
          <!-- {{ hoistDualB }} -->
        </div>
      </div>

      <div class="container troubleshoot">
        <div class="row m-50">
          <div class="col-2" v-if="cranes.length">
            B Dimension Crane: {{ cranes[0].craneSideApproach }}
          </div>
          <div class="col-2">
            <!-- C Dimension Crane: {{ cranes[0].craneElectricSideApproach }} -->
          </div>
          <div class="col-2"></div>
          <div class="col-2"></div>
        </div>
      </div>

      <div class="container troubleshoot">
        <div class="row m-50">
          <div class="col-2" v-if="cranes.length">
            Flange Width: {{ cranes[0].craneFlange }}
          </div>
          <div class="col-2">
            <!-- ISSUE Hoist Headroom Range {{ hoistSingle['hoistHeadroomC'] }} -->
          </div>
          <div class="col-2">
            <!-- Headroom Total | crane[distanceAboveRail]  + hoist[hoistHeadroomC] {{ headroomTotal }}  -->
            <!-- ISSUE Headroom Total | crane[distanceAboveRail] {{ cranes[0].craneDistanceAboveRail }} + hoist[hoistHeadroomC] {{ hoistSingle['hoistHeadroomC'] }} -->
          </div>
          <div class="col-2">
            Single {{ hoistSingleHeadroomC }}
            <br />
            <br />
            Single Total Headroom {{ hoistSingleHeadroomTotal }}
          </div>
          <div class="col-2">
            DualA {{ hoistDualAHeadroomC }}
            <br />
            <br />
            Single Total Headroom {{ hoistDualAHeadroomTotal }}
          </div>
          <div class="col-2">
            DualB {{ hoistDualBHeadroomC }}
            <br />
            <br />
            Single Total Headroom {{ hoistDualBHeadroomTotal }}
          </div>
          <div class="col-2">
            <button @click="checkForm">Download PDF</button>
          </div>
        </div>
      </div>

      <div class="row troubleshoot">
        <div class="col-4 troubleshoot">
          hoistsList
          {{ hoistsList }}
        </div>
        <div class="col-4 troubleshoot">
          hoistsListCompareLift
          {{ hoistsListCompareLift }}
        </div>
        <div class="col-2">
          Crane
          {{ cranes[0] }}
        </div>
        <div class="col-2">
          Hoist Single
          {{ hoistSingle }}
        </div>
        <div class="col-1">
          Single | Electric Side Approach
          {{ hoistSingleElectrificationSideApproach }}
        </div>
        <div class="col-1">
          Single | Side Approach
          {{ hoistSingleSideApproach }}
        </div>
        <div class="col-2">
          Hoist Dual A
          {{ hoistDualA }}
        </div>
        <div class="col-2">
          Hoist Dual B
          {{ hoistDualB }}
        </div>
        <div class="col-1">
          Dual | Electric Side Approach
          {{ hoistDualElectrificationSideApproach }}
        </div>
        <div class="col-1">
          Dual | Side Approach
          {{ hoistDualSideApproach }}
        </div>
      </div>
    </div>
    <!-- hoistSelection -->

    <hr />
    <div class="container craneRequirements">
      <div class="row">
        <div class="col-3 text-left">
          <strong>Crane Requirements</strong>
        </div>
      </div>
      <div class="row m-20">
        <div
          class="col-2 text-left"
          v-bind:class="{ required: requiredVoltage }"
        >
          <strong>Voltage:</strong>
          <!-- <strong>Voltage: {{ voltage }}</strong> -->
          <b-form-radio-group
            id="radio-group-voltage"
            v-model="voltage"
            :options="voltages"
            stacked
          ></b-form-radio-group>
        </div>
        <div
          class="col-4 text-left"
          v-bind:class="{ required: requiredControlType }"
        >
          <strong>Control Type:</strong>
          <!-- <strong>Control Type: {{ controlType }}</strong> -->
          <b-form-radio-group
            id="radio-group-control-type"
            v-model="controlType"
            :options="controlTypes"
            stacked
          ></b-form-radio-group>
        </div>
        <div class="col-3 text-left" v-bind:class="{ required: requiredSpeed }">
          <strong>Speed:</strong>
          <!-- <strong>Speed: {{ speed }}</strong> -->
          <b-form-radio-group
            id="radio-group-speed"
            v-model="speed"
            :options="speeds"
            stacked
          ></b-form-radio-group>
        </div>
      </div>
      <div class="row m-20">
        <div
          class="col-6 text-left"
          style="font-size: 14px"
          v-bind:class="{ required: requiredControlMethod }"
        >
          <strong>Control Method: </strong>
          <!-- <strong>Control Method: {{ controlMethod }}</strong> -->
          <b-form-radio-group
            id="radio-group-control-method"
            v-model="controlMethod"
            :options="controlMethods"
            stacked
          ></b-form-radio-group>
        </div>
        <div
          class="col-3 text-left"
          v-bind:class="{ required: requiredServiceRequirement }"
        >
          <!-- <strong>Service Requirement: {{ serviceRequirement }}</strong> -->
          <strong>Service Requirement: </strong>
          <b-form-radio-group
            id="radio-group-service-requirement"
            v-model="serviceRequirement"
            :options="serviceRequirements"
            stacked
          ></b-form-radio-group>
        </div>
        <div class="col-3 text-left">
          <strong>ASCE Rail Size: </strong>
          <!-- <strong>ASCE Rail Size: {{ asceRailSize }}</strong> -->
          <b-form-radio-group
            id="radio-group-asce-rail-size"
            v-model="asceRailSize"
            :options="asceRailSizes"
            stacked
          ></b-form-radio-group>
        </div>
      </div>
    </div>
    <!-- craneRequirements -->

    <hr />

    <div class="container optionsAdditional">
      <div class="row">
        <div class="col-3 text-left">
          <strong>Options</strong>
        </div>
      </div>
      <div class="row m-20">
        <div class="col-3 text-left">
          <strong>Bridge Limit: </strong>
          <!-- <strong>Bridge Limit: {{ bridgeLimit }}</strong> -->
          <b-form-radio-group
            id="radio-group-bridge-limit"
            v-model="bridgeLimit"
            :options="bridgeLimits"
            stacked
          ></b-form-radio-group>
        </div>
        <div class="col-3 text-left">
          <strong>Trolley Limit: </strong>
          <!-- <strong>Trolley Limit: {{ trolleyLimit }}</strong> -->
          <b-form-radio-group
            id="radio-group-trolley-limit"
            v-model="trolleyLimit"
            :options="trolleyLimits"
            stacked
          ></b-form-radio-group>
        </div>
        <div class="col-3 text-left">
          <strong>Collision Avoidance: </strong>
          <!-- <strong>Collision Avoidance: {{ collisionAvoidance }}</strong> -->
          <b-form-radio-group
            id="radio-group-collision-avoidance"
            v-model="collisionAvoidance"
            :options="collisionAvoidances"
            stacked
          ></b-form-radio-group>
        </div>
        <div class="col-3 text-left">
          <!-- <strong>Warning Device: {{ warningDevice }}</strong> -->
          <strong>Warning Device: </strong>
          <b-form-radio-group
            id="radio-group-warning-device"
            v-model="warningDevice"
            :options="warningDevices"
            stacked
          ></b-form-radio-group>
        </div>
      </div>
    </div>
    <!-- optionsAdditional -->
    <hr />
    <div class="container" style="margin-bottom: 100px">
      <div class="row">
        <div class="col-2">
          <b-button style="background-color: #e1251b" @click="checkForm"
            >Download PDF</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { jsPDF } from "jspdf";
import hoists from "../assets/hoists.js";
// import crane_sgtrdm_rm from "../assets/crane-sgtrdm-rm.js";
// import crane_sgtrdm_detroit from "../assets/crane-sgtrdm-detroit.js";
// import arrows from "../assets/arrows.js";
// import hoists_drawings from "../assets/hoists-drawings.js";
import emailjs from "emailjs-com";

export default {
  created: function () {
    fetch("cranes.json")
      .then((response) => response.json())
      .then((data) => (this.cranesDataList = data))
      .then((data) => (this.cranes = data));
  },
  methods: {
    sendEmail() {
      return new Promise((resolve, reject) => {
        fetch("/crane", {
          method: "POST",
          body: JSON.stringify(this.craneSpec),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((resp) => resp.json())
          .then((data) => {
            if (data.message === 'success') {
              this.quoteSent = true;
              resolve(data);
            } else {
              console.error(data.message);
              this.errors.push(data.message);
              reject(data);
            }
          })
          .catch((reason) => {
            console.error(reason);
            this.errors.push(reason);
            reject(reason);
          });
      });
    },
    checkSpanLimit: function () {
      // alert('Check Span Limit');
      this.errors = [];
      if (this.spanFeet > 100) {
        this.errors.push("Over 100 Feet | Crane Span Limit 100 Feet");
        this.spanFeet = null;
        this.spanInch = null;
        this.spanFraction = null;
      }
      if (this.spanFeet == 100 && this.spanInch != null) {
        this.errors.push("Over 100 Feet | Crane Span Limit 100 Feet");
        this.spanFeet = null;
        this.spanInch = null;
        this.spanFraction = null;
      }
      if (this.spanFeet == 100 && this.spanFraction != null) {
        this.errors.push("Over 100 Feet | Crane Span Limit 100 Feet");
        this.spanFeet = null;
        this.spanInch = null;
        this.spanFraction = null;
      }
      // Show Modal
      if (this.errors.length > 0) {
        this.requirementModal = true;
        return;
      }
    },
    checkForm: function () {
      this.errors = [];

      if (this.capacity == null) {
        this.errors.push("Capacity");
        this.requiredCapacity = true;
      } else {
        this.requiredCapacity = null;
      }

      if (this.spanFeet == null) {
        this.errors.push("Span");
        this.requiredSpan = true;
      } else {
        this.requiredSpan = null;
      }

      if (this.liftFeet == null) {
        this.errors.push("Lift");
        this.requiredLift = true;
      } else {
        this.requiredLift = null;
      }

      if (this.hoistAmount == null) {
        this.errors.push("Hoist Amount");
        this.requiredHoistAmount = true;
      } else {
        this.requiredHoistAmount = null;
      }

      if (this.hoistManufacturer == null) {
        this.errors.push("Hoist Manufacturer");
        this.requiredHoistManufacturer = true;
      } else {
        this.requiredHoistManufacturer = null;
      }

      if (this.hoistAmount == "single") {
        if (this.capacitySingle == null) {
          this.errors.push("Single Hoist Capacity");
          this.requiredHoistSingle = true;
        } else {
          this.requiredHoistSingle = null;
        }
      }

      if (this.hoistAmount == "dual") {
        if (this.capacityDualA == null) {
          this.errors.push("Dual A Hoist Capacity");
          this.requiredHoistDualA = true;
        }
        if (this.capacityDualB == null) {
          this.errors.push("Dual B Hoist Capacity");
          this.requiredHoistDualB = true;
        }
      }

      if (this.voltage == null) {
        this.errors.push("Voltage");
        this.requiredVoltage = true;
      } else {
        this.requiredVoltage = null;
      }

      if (this.controlType == null) {
        this.errors.push("Control Type");
        this.requiredControlType = true;
      } else {
        this.requiredControlType = null;
      }

      if (this.speed == null) {
        this.errors.push("Speed");
        this.requiredSpeed = true;
      } else {
        this.requiredSpeed = null;
      }

      if (this.controlMethod == null) {
        this.errors.push("Control Method");
        this.requiredControlMethod = true;
      } else {
        this.requiredControlMethod = null;
      }

      if (this.serviceRequirement == null) {
        this.errors.push("Service Requirement");
        this.requiredServiceRequirement = true;
      } else {
        this.requiredServiceRequirement = null;
      }

      // Show Modal
      if (this.errors.length > 0) {
        this.requirementModal = true;
        return;
      }

      if(this.asceRailFeet == null){
        this.asceRailFeet = 0;
      }
      
      if(this.obstructionFeet == null){
        this.obstructionFeet = 0;
      }
      
      this.craneSpec.asceRailFeet = this.asceRailFeet;
      this.craneSpec.asceRailInch = this.asceRailInch;
      this.craneSpec.asceRailFraction = this.asceRailFraction;
      this.craneSpec.asceRailSize = this.asceRailSize;
      this.craneSpec.beam = this.cranes[0].beam;
      this.craneSpec.bridgeLimit = this.bridgeLimit;
      this.craneSpec.capacity = this.capacity;
      this.craneSpec.controlMethod = this.controlMethod;
      this.craneSpec.controlType = this.controlType;
      // this.craneSpec.craneDistanceAboveRail = this.cranes[0].craneDistanceAboveRail;
      // this.craneSpec.dimensionC = this.dimensionC;
      // this.craneSpec.dimensionB = this.dimensionB;
      this.craneSpec.direction = this.direction;
      this.craneSpec.endApproach = this.cranes[0].craneEndApproach;
      this.craneSpec.craneElectrificationSideApproach = this.cranes[0].craneElectrificationSideApproach;
      this.craneSpec.craneSideApproach = this.cranes[0].craneSideApproach;
      this.craneSpec.flange = this.cranes[0].craneFlange;
      this.craneSpec.hoistAmount = this.hoistAmount;
      this.craneSpec.hoistSingle = this.hoistSingle;
      this.craneSpec.hoistDualA = this.hoistDualA;
      this.craneSpec.hoistDualB = this.hoistDualB;
      this.craneSpec.hoistManufacturer = this.hoistManufacturer;
      this.craneSpec.hpRpm = this.cranes[0].craneHpRpm;
      this.craneSpec.liftFeet = this.liftFeet;
      this.craneSpec.obstructionFeet = this.obstructionFeet;
      this.craneSpec.obstructionInch = this.obstructionInch;
      this.craneSpec.obstructionFraction = this.obstructionFraction;
      this.craneSpec.overhang = this.cranes[0].craneOverhang;
      this.craneSpec.serviceRequirement = this.serviceRequirement;
      this.craneSpec.spanFeet = this.spanFeet;

      if(this.spanFraction == null){
        this.spanFraction = "0";
      }
      this.craneSpec.spanFraction = this.spanFraction;
      
      if(this.spanInch == null){
        this.spanInch = 0;
      }
      this.craneSpec.spanInch = this.spanInch;
      
      this.craneSpec.speed = this.speed;
      this.craneSpec.weight = this.cranes[0].craneWeight;
      this.craneSpec.wheelBase = this.cranes[0].craneWheelBase;
      this.craneSpec.wheelDiameter = this.cranes[0].craneWheelDiameter;
      this.craneSpec.wheelLoad = this.cranes[0].craneWheelLoad;
      this.craneSpec.voltage = this.voltage;

      // Offer Quote
      this.requestQuoteModal = true;
    },
    hideRequirementModal: function () {
      this.$refs["requirement-modal"].hide();
      this.requirementModal = false;
    },
    hideRequestQuoteModal: function () {
      this.$refs["request-quote-modal"].hide();
      this.requestQuoteModal = false;
    },
    sendPDFOnly: function () {
      this.sendEmail()
        .then((data) => {
          window.alert("PDF Sent");
          window.location = (`${window.location.origin}${data.quoteFile}`);
        })
        .catch((err) => {
          window.alert(`Error: PDF Not Sent. \n ${err}`);
          console.log(` ${err}`);
        });
    },
    hideQuoteModal: function () {
      this.$refs["quote-modal"].hide();
      this.quoteModal = false;
    },
    hideThankYouModal: function () {
      this.$refs["thank-you-modal"].hide();
      this.thankYouModal = false;
    },
    showQuoteModal: function () {
      this.$refs["request-quote-modal"].hide();
      this.requestQuoteModal = false;
      this.$refs["quote-modal"].show();
    },
    showThankYouModal: function () {
      this.$refs["quote-modal"].hide();
      this.quoteModal = false;
      this.$refs["thank-you-modal"].show();
    },
    sendPDFQuoteInfo: function () {
      this.craneSpec.quoteName = this.quoteName;
      this.craneSpec.quoteCompany = this.quoteCompany;
      this.craneSpec.quoteCity = this.quoteCity;
      this.craneSpec.quoteState = this.quoteState;
      this.craneSpec.quotePhone = this.quotePhone;
      this.craneSpec.quoteEmail = this.quoteEmail;
      this.craneSpec.quoteBuilding = this.quoteBuilding;
      this.craneSpec.quoteRunway = this.quoteRunway;
      this.craneSpec.quoteRunwaySpecifications = this.quoteRunwaySpecifications;
      this.craneSpec.quoteInstall = this.quoteInstall;
      this.craneSpec.quoteTimeline = this.quoteTimeline;
      this.sendEmail()
        .then((data) => {
          window.alert("PDF & Details Sent");
          window.location = `${window.location.origin}${data.quoteFile}`;
        })
        .catch((err) => {
          window.alert(`Error: PDF & Details Not Sent. \n ${err}`);
        });
    },
    sendCanvasAsAttachment(canvas) {
      var base64 = canvas.toDataURL();
      emailjs.send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", {
        content: base64,
      });
    },
    filterCranesType: function () {
      this.cranesDataList.forEach((e) => {
        if (e.craneType == "Single Girder Top Running") {
          this.cranesDataListType.push(e);
        }
      });
    },
    filterCranesCapacitySpan: function () {
      this.cranesDataListCapacity = [];
      this.cranesDataListType.forEach((e) => {
        if (e.craneCapacity >= this.capacity) {
          if (
            this.spanFeet == e.craneSpanFeet &&
            (this.spanInch == null) & (this.spanFraction == null)
          ) {
            this.cranesDataListCapacity.push(e);
          } else if (this.spanFeet < e.craneSpanFeet) {
            this.cranesDataListCapacity.push(e);
          }
        }
      });
      this.cranesDataListCapacity.sort(this.sortCranes);
      this.cranes = this.cranesDataListCapacity;
    },
    sortCranes: function (a, b) {
      if (a.craneCapacity < b.craneCapacity) {
        return -1;
      }
      if (a.craneCapacity > b.craneCapacity) {
        return 1;
      }
      if (a.craneSpanFeet < b.craneSpanFeet) {
        return -1;
      }
      if (a.craneSpanFeet > b.craneSpanFeet) {
        return 1;
      }
      if (a.craneSpanInch < b.craneSpanInch) {
        return -1;
      }
      if (a.craneSpanInch > b.craneSpanInch) {
        return 1;
      }
      return 0;
    },
    formatFeetInchFraction: function (feet, inch, fraction) {
      var format = "";
      if (fraction != null && fraction != "0") {
        if (inch != null) {
          format = feet + "'-" + inch + "-" + fraction + "''";
        } else {
          format = feet + "'-" + fraction + "''";
        }
      } else if (inch != null) {
        format = feet + "'-" + inch + "''";
      } else {
        format = feet + "'-0''";
      }
      return format;
    },
    setHoistDropDown: function () {
      // Setting the DropDown choices of the hoist, based on the Capacity choice of crane
      // this.capacitiesLimit = [];
      this.capacitiesLimit = this.capacities;
      // if(this.hoistAmount == "single") {
      //     this.capacities.forEach((e) => {
      //       if (e.value == this.capacity) {
      //       alert("Add the CapacitiesLimit: " + e.value);
      //       this.capacitiesLimit.push(e);
      //     }
      //   });
      // }
      // if(this.hoistAmount == "dual") {
      //     this.capacities.forEach((e) => {
      //       if (e.value <= this.capacity) {
      //       this.capacitiesLimit.push(e);
      //     }
      //   });
      // }
      
      // Cycle through the list of hoists that work within the Capacity / Lift
      // fill up the capacitiesLimit list with the hoist capacities

    },
    setHoist: function () {
      if (this.hoistManufacturer == null) {
        return;
      }

      // if single or dual is set, then run
      function compareLift(a, b) {
        if (a.hoistLift < b.hoistLift) {
          return -1;
        }
        if (a.hoistLift > b.hoistLift) {
          return 1;
        }
        return 0;
      }
      function compareCapacity(a, b) {
        if (a.hoistCapacity < b.hoistCapacity) {
          return -1;
        }
        if (a.hoistCapacity > b.hoistCapacity) {
          return 1;
        }
        return 0;
      }

      this.hoistSingle = [];
      this.hoistDualA = [];
      this.hoistDualB = [];

      // Filter Manufacturer
      var filterHoistManufacturer = [];
      this.hoists.forEach((e) => {
        if (e.hoistManufacturer == this.hoistManufacturer) {
          filterHoistManufacturer.push(e);
        }
      });
      // alert("filterHoistManufacturer: " + filterHoistManufacturer.length);

      // Filter Type
      var filterHoistType = [];
      filterHoistManufacturer.forEach((e) => {
        if (e.hoistType == "Monorail Hoist") {
          filterHoistType.push(e);
        }
      });
      // alert("filterHoistType: " + filterHoistType.length);

      // Filter Capacity
      // var filterHoistCapacity = [];
      // filterHoistType.forEach((e) => {
      //   if (e.hoistCapacity <= this.capacity) {
      //     filterHoistCapacity.push(e);
      //   }
      // });
      // alert("filterHoistCapacity: " + filterHoistCapacity.length);

      // Filter Lift
      var filterHoistLift = [];
      // filterHoistCapacity.forEach((e) => {
      filterHoistType.forEach((e) => {
        if (this.liftFeet <= e.hoistLift) {
          filterHoistLift.push(e);
        }
      });
      // alert("filterHoistLift " + filterHoistLift.length);

      filterHoistLift.sort(compareLift);
      filterHoistLift.sort(compareCapacity);
      
      // alert("filterHoistLift " + filterHoistLift.length);

      // Filter Single Hoist
      // this.capacitySingle = this.capacity;
      var filterHoistSingle = [];
      filterHoistLift.forEach((e) => {
        if (e.hoistCapacity >= this.capacitySingle) {
          // alert(JSON.stringify(e));
          filterHoistSingle.push(e);
        }
      });
      // this.capacitiesLimit = filterHoistSingle;
      if (filterHoistSingle.length == 0 && this.hoistAmount == "single") {
        alert(
          "Single Hoist : No Hoist Found For That Capacity & Lift! Please select another Capacity."
        );
        this.capacitySingle = null;
        // this.capacitiesLimit = [];
      } 
      // else {
      //   this.capacitySingle = this.capacity;
      //   this.capacitiesLimit = [];
      //   this.capacities.forEach((e) => {
      //     // The Line Controlling the Capacity DropDown
      //     if (e.value <= this.capacitySingle) {
      //       this.capacitiesLimit.push(e);
      //     }
      //   })
      // }

      this.hoistSingle = filterHoistSingle[0];      

      // Filter Dual A
      var filterHoistDualA = [];
      filterHoistLift.forEach((e) => {
        if (e.hoistCapacity >= this.capacityDualA) {
          filterHoistDualA.push(e);
        }
      });
      if (filterHoistDualA.length == 0 && this.hoistAmount == "dual") {
        alert(
          "Dual Hoist A : No Hoist Found For That Capacity & Lift! Please select another Capacity."
        );
        this.capacityDualA = null;
      }
      this.hoistDualA = filterHoistDualA[0];

      // Filter  Dual B
      var filterHoistDualB = [];
      filterHoistLift.forEach((e) => {
        if (e.hoistCapacity >= this.capacityDualB) {
          filterHoistDualB.push(e);
        }
      });
      if (
        filterHoistDualB.length == 0 &&
        this.hoistAmount == "dual" &&
        this.hoistDualA.hoistCapacity != 0
      ) {
        alert(
          "Dual Hoist B : No Hoist Found For That Capacity & Lift! Please select another Capacity."
        );
        this.capacityDualB = null;
      }
      this.hoistDualB = filterHoistDualB[0];
    },
  },
  data() {
    return {
      quoteSent: false,
      errors: [],
      requiredCapacity: null,
      requiredSpan: null,
      requiredLift: null,
      requiredHoistAmount: null,
      requiredHoistManufacturer: null,
      requiredHoistSingle: null,
      requiredHoistDualA: null,
      requiredHoistDualB: null,
      requiredVoltage:null,
      requiredServiceRequirement:null,
      requiredControlType:null,
      requiredControlMethod:null,
      requiredSpeed:null,
      requirementModal: false,
      requestQuoteModal: false,
      quoteModal: false,
      thankYouModal: false,
      cranePDF: {
        quoteFile: "",
      },
      cranesDataList: [],
      cranesDataListType: [],
      cranesDataListCapacity: [],
      craneType: "Single Girder Top Running",
      cranes: [],
      hoists: hoists,
      hoistsList: [],
      hoistsListCompareCapacity: [],
      hoistsListCompareLift: [],
      capacity: null,
      capacitySingle: null,
      capacityDualA: null,
      capacityDualB: null,
      capacitiesLimit: [],
      capacities: [
        { value: null, text: "Select Ton" },
        { value: 0.25, text: "1/4" },
        { value: 0.5, text: "1/2" },
        { value: 0.75, text: "3/4" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7.5, text: "7.5" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
        { value: 12.5, text: "12.5" },
        { value: 15, text: "15" },
      ],
      spanFeet: null,
      spanInch: 0,
      spanFraction: "0",
      inches: [
        { value: 0, text: "Inch" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
        { value: 11, text: "11" },
        { value: 12, text: "12" },
      ],
      fractions: [
        { value: "0", text: "Fraction" },
        { value: "1/8", text: "1/8" },
        { value: "3/16", text: "3/16" },
        { value: "1/4", text: "1/4" },
        { value: "5/16", text: "5/16" },
        { value: "3/8", text: "3/8" },
        { value: "7/16", text: "7/16" },
        { value: "1/2", text: "1/2" },
        { value: "9/16", text: "9/16" },
        { value: "5/8", text: "5/8" },
        { value: "11/16", text: "11/16" },
        { value: "3/4", text: "3/4" },
        { value: "13/16", text: "13/16" },
        { value: "7/8", text: "7/8" },
        { value: "15/16", text: "15/16" },
      ],
      direction: null,
      directions: [
        { value: null, text: "Select North" },
        { value: "up", html: "&uarr;" },
        { value: "down", html: "&darr;" },
        { value: "left", html: "&larr;" },
        { value: "right", html: "&rarr;" },
      ],
      electrification: null,
      asceRailFeet: null,
      asceRailInch: 0,
      asceRailFraction:  "0",
      obstructionFeet: null,
      obstructionInch: 0,
      obstructionFraction: "0",
      liftFeet: null,
      hoistAmounts: [
        { value: "single", text: "Single" },
        { value: "dual", text: "Dual" },
      ],
      hoistAmount: null,
      hoistManufacturers: [
        { value: "rm", text: "R&M" },
        { value: "detroit", text: "Detroit" },
      ],
      hoistManufacturer: null,
      hoistSingle: [],
      hoistSingleHeadroomC: null,
      hoistSingleHeadroomTotal: null,
      hoistSingleElectrificationSideApproach: null,
      hoistSingleSideApproach: null,
      hoistDualA: [],
      hoistDualAHeadroomC: null,
      hoistDualAHeadroomTotal: null,
      hoistDualAElectrificationSideApproach: null,
      hoistDualASideApproach: null,
      hoistDualB: [],
      hoistDualBHeadroomC: null,
      hoistDualBHeadroomTotal: null,
      hoistDualBElectrificationSideApproach: null,
      hoistDualBSideApproach: null,
      hoistDualElectrificationSideApproach: null,
      hoistDualSideApproach: null,
      headroomTotal: null,
      voltage: null,
      voltages: [
        { value: "460/3/60", text: "460/3/60" },
        { value: "230/3/60", text: "230/3/60" },
        { value: "208/3/60", text: "208/3/60" },
        { value: "220/1/60", text: "220/1/60" },
        { value: "120/1/60", text: "120/1/60" },
        { value: "575/3/60", text: "575/3/60" },
      ],
      controlType: null,
      controlTypes: [
        {
          value: "Single speed inverter control",
          text: "Single speed inverter control",
        },
        {
          value: "Two step inverter control",
          text: "Two step inverter control",
        },
        {
          value: "Three step inverter control",
          text: "Three step inverter control",
        },
      ],
      speed: null,
      speeds: [
        { value: "50 FPM", text: "50 FPM" },
        { value: "80 FPM", text: "80 FPM" },
        { value: "100 FPM", text: "100 FPM" },
        { value: "120 FPM", text: "120 FPM" },
      ],
      controlMethod: null,
      controlMethods: [
        {
          value: "Pushbutton Suspended From The Hoist",
          text: "Pushbutton Suspended From The Hoist",
        },
        {
          value: "Pushbutton Suspended From The Bridge Control Panel",
          text: "Pushbutton Suspended From The Bridge Control Panel",
        },
        {
          value: "Pushbutton Suspended From A Separate Track",
          text: "Pushbutton Suspended From A Separate Track",
        },
        {
          value:
            "RC, Backup PB Suspended From Separate Track",
          text: "Radio Control, Backup Pushbutton Suspended From A Separate Track",
        },
        {
          value: "RC, Backup PB Suspended From Bridge",
          text: "Radio Control, Backup Pushbutton Suspended From The Bridge",
        },
      ],
      serviceRequirement: null,
      serviceRequirements: [
        { value: "Indoor", text: "Indoor" },
        { value: "Outdoor", text: "Outdoor" },
        { value: "Corrosive", text: "Corrosive" },
        { value: "Explosion Proof", text: "Explosion Proof" },
      ],
      bridgeLimit: null,
      bridgeLimits: [
        { value: "Slow Down And Stop", text: "Slow Down And Stop" },
        { value: "Slow Down", text: "Slow Down" },
        { value: "Stop", text: "Stop" },
      ],
      trolleyLimit: null,
      trolleyLimits: [
        { value: "Slow Down And Stop", text: "Slow Down And Stop" },
        { value: "Slow Down", text: "Slow Down" },
        { value: "Stop", text: "Stop" },
      ],
      collisionAvoidance: null,
      collisionAvoidances: [
        { value: "Photo Eye", text: "Photo Eye" },
        { value: "Two Event Laser", text: "Two Event Laser" },
      ],
      warningDevice: null,
      warningDevices: [
        { value: "Horn", text: "Horn" },
        { value: "Strobe Light", text: "Strobe Light" },
      ],
      asceRailSize: null,
      asceRailSizes: [
        { value: "25#", text: "25#" },
        { value: "30#", text: "30#" },
        { value: "40#", text: "40#" },
        { value: "60#", text: "60#" },
      ],
      form: {
        name: "",
        company: "",
        city: "",
        state: "",
        phone: "",
        email: "",
        bulding: "",
      },
      user_name: null,
      user_email: null,
      quoteName: null,
      quoteCompany: null,
      quoteCity: null,
      quoteState: null,
      quotePhone: null,
      quoteEmail: null,
      quoteBuilding: null,
      quoteRunway: null,
      quoteRunwaySpecifications: null,
      quoteInstall: null,
      quoteTimeline: null,
      craneSpec: {
        asceRailFeet: 0,
        asceRailInch: 0,
        asceRailFraction: "0",
        asceRailSize: null,
        beam: null,
        bridgeLimit: null,
        capacity: null,
        capacitySingle: null,
        capacityDualA: null,
        capacityDualB: null,
        controlMethod: null,
        controlType: null,
        craneDistanceAboveRail: null,
        craneElectrificationSideApproach: null,
        craneSideApproach: null,
        direction: null,
        endApproach: null,
        flange: null,
        hoistAmount: null,
        hoistSingle: [],
        hoistDualA: [],
        hoistDualB: [],
        hoistManufacturer: null,
        hpRpm: null,
        liftFeet: null,
        obstructionFeet: 0,
        obstructionInch: 0,
        obstructionFraction: "0",
        overhang: null,
        serviceRequirement: null,
        spanFeet: null,
        spanInch: null,
        spanFraction: "0",
        speed: null,
        weight: null,
        wheelBase: null,
        wheelDiameter: null,
        wheelLoad: null,
        voltage: null,
        quoteName: null,
        quoteCompany: null,
        quoteCity: null,
        quoteState: null,
        quotePhone: null,
        quoteEmail: null,
        quoteBuilding: null,
        quoteRunway: null,
        quoteRunwaySpecifications: null,
        quoteInstall: null,
        quoteTimeline: null,
      },
    };
  },
};
</script>
<style scoped>
.required {
  color: red;
}
.requiredBorder {
  border-color: red;
}
.m-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.m-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.crane {
  background: url("../assets/crane-sgtrdm.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  height: 500px;
}
.craneDirection {
  background: url("../assets/crane-sgtrdm.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  height: 125px;
}
.craneDetailContainer {
  background: url("../assets/crane-sgtrdm.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 275;
}
.troubleshoot {
  display: none;
}
</style>