const hoists = [
  {
    "hoistModel": "DB11T4113",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$21,000.00",
    "hoistLiftFeet": 41,
    "hoistLiftInch": 7,
    "hoistLift": 41.7,
    "hoistSpeed": "13 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 7,
    "hoistReeving": "2 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 6,
    "hoistWeight": 2000,
    "hoistElectrificationSideApproach": 30.25,
    "hoistSideApproach": 30.25,
    "hoistHeadroomC": 3,
    "hoistAboveHeadroomC": 24.25
  },
  {
    "hoistModel": "DR10M2826",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$12,636.37",
    "hoistLiftFeet": 28,
    "hoistLiftInch": 6,
    "hoistLift": 28.6,
    "hoistSpeed": "26 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 9,
    "hoistReeving": "2 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "60 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": "6-1/2",
    "hoistWeight": 1600,
    "hoistElectrificationSideApproach": 25.375,
    "hoistSideApproach": 17.375,
    "hoistHeadroomC": 26,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DB11M4126",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$17,000.00",
    "hoistLiftFeet": 41,
    "hoistLiftInch": 7,
    "hoistLift": 41.7,
    "hoistSpeed": "26 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 10,
    "hoistReeving": "2 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "60 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1600,
    "hoistElectrificationSideApproach": 31.375,
    "hoistSideApproach": 22.375,
    "hoistHeadroomC": 28,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DB16T4126M80",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": "7-1/2",
    "hoistCapacity": 7.5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$23,000.00",
    "hoistLiftFeet": 41,
    "hoistLiftInch": 7,
    "hoistLift": 41.7,
    "hoistSpeed": "26 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 15,
    "hoistReeving": "2 part double",
    "hoistBrakes": "Inverter duty disc brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": "6-1/2",
    "hoistWeight": 1900,
    "hoistElectrificationSideApproach": 30.25,
    "hoistSideApproach": 30.25,
    "hoistHeadroomC": 8.75,
    "hoistAboveHeadroomC": 24.25
  },
  {
    "hoistModel": "DB22T3818",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$26,000.00",
    "hoistLiftFeet": 38,
    "hoistLiftInch": 8,
    "hoistLift": 38.8,
    "hoistSpeed": "18 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 14,
    "hoistReeving": "3 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": "6-1/2",
    "hoistWeight": 2200,
    "hoistElectrificationSideApproach": 30.25,
    "hoistSideApproach": 30.25,
    "hoistHeadroomC": 4.75,
    "hoistAboveHeadroomC": 24.25
  },
  {
    "hoistModel": "DB22M2013",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$18,000.00",
    "hoistLiftFeet": 20,
    "hoistLiftInch": 2,
    "hoistLift": 20.2,
    "hoistSpeed": "13 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 10,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": "6-1/2",
    "hoistWeight": 1700,
    "hoistElectrificationSideApproach": 31.375,
    "hoistSideApproach": 22.375,
    "hoistHeadroomC": 30.5,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DB33T2913",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$28,000.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 1,
    "hoistLift": 29.1,
    "hoistSpeed": "13 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 15,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": "6-1/2",
    "hoistWeight": 2200,
    "hoistElectrificationSideApproach": "",
    "hoistSideApproach": "",
    "hoistHeadroomC": "",
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DB33M2913",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$23,000.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 1,
    "hoistLift": 29.1,
    "hoistSpeed": "13 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 15,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 6,
    "hoistWeight": 1900,
    "hoistElectrificationSideApproach": 41.125,
    "hoistSideApproach": 28.875,
    "hoistHeadroomC": 37,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DRD40T3015",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$36,363.64",
    "hoistLiftFeet": 30,
    "hoistLiftInch": 6,
    "hoistLift": 30.6,
    "hoistSpeed": "15 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 20,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 6,
    "hoistWeight": 3000,
    "hoistElectrificationSideApproach": 27.75,
    "hoistSideApproach": 27.75,
    "hoistHeadroomC": 24,
    "hoistAboveHeadroomC": 23.25
  },
  {
    "hoistModel": "DBF50T2915",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 25,
    "hoistCapacity": 25,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$61,272.73",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 7,
    "hoistLift": 29.7,
    "hoistSpeed": "15 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 30,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": "8-1/4",
    "hoistWeight": 4600,
    "hoistElectrificationSideApproach": 36.25,
    "hoistSideApproach": 36.25,
    "hoistHeadroomC": 19,
    "hoistAboveHeadroomC": 35
  },
  {
    "hoistModel": "DR120T3312",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 60,
    "hoistCapacity": 60,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$118,181.82",
    "hoistLiftFeet": 33,
    "hoistLiftInch": 0,
    "hoistLift": 33,
    "hoistSpeed": "12 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 53,
    "hoistReeving": "6 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "5 @ 3600",
    "hoistTrolleyBrakes": "Inverter Duty Motor Brake",
    "hoistWheelDiameter": 12,
    "hoistWeight": 8000,
    "hoistElectrificationSideApproach": 38,
    "hoistSideApproach": 38,
    "hoistHeadroomC": 46,
    "hoistAboveHeadroomC": 36.5
  },
  {
    "hoistModel": "DB22T2013",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 11,
    "hoistCapacity": 11,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$21,000.00",
    "hoistLiftFeet": 20,
    "hoistLiftInch": 2,
    "hoistLift": 20.2,
    "hoistSpeed": "13 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 10,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 5,
    "hoistWeight": 1800,
    "hoistElectrificationSideApproach": 30.25,
    "hoistSideApproach": 30.25,
    "hoistHeadroomC": 4.75,
    "hoistAboveHeadroomC": 24.25
  },
  {
    "hoistModel": "DB22LH2618",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 11,
    "hoistCapacity": 11,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$29,000.00",
    "hoistLiftFeet": 26,
    "hoistLiftInch": 11,
    "hoistLift": 26.11,
    "hoistSpeed": "18 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 14,
    "hoistReeving": "3 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": "6-1/2",
    "hoistWeight": 1800,
    "hoistElectrificationSideApproach": "",
    "hoistSideApproach": "",
    "hoistHeadroomC": "",
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DB22T2913",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 11,
    "hoistCapacity": 11,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$22,000.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 1,
    "hoistLift": 29.1,
    "hoistSpeed": "13 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 10,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 5,
    "hoistWeight": 2000,
    "hoistElectrificationSideApproach": 30.25,
    "hoistSideApproach": 30.25,
    "hoistHeadroomC": 4.75,
    "hoistAboveHeadroomC": 24.25
  },
  {
    "hoistModel": "DB22M2913",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 11,
    "hoistCapacity": 11,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$19,000.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 1,
    "hoistLift": 29.1,
    "hoistSpeed": "13 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 10,
    "hoistReeving": "4 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 6,
    "hoistWeight": 1800,
    "hoistElectrificationSideApproach": 37.875,
    "hoistSideApproach": 28.875,
    "hoistHeadroomC": 30.5,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DB22M3818",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 11,
    "hoistCapacity": 11,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$21,000.00",
    "hoistLiftFeet": 38,
    "hoistLiftInch": 8,
    "hoistLift": 38.8,
    "hoistSpeed": "18 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 14,
    "hoistReeving": "3 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 6,
    "hoistWeight": 1900,
    "hoistElectrificationSideApproach": 41.125,
    "hoistSideApproach": 28.875,
    "hoistHeadroomC": 36,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "DB22M5018",
    "hoistManufacturer": "detroit",
    "hoistCapacityNumber": 11,
    "hoistCapacity": 11,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$22,000.00",
    "hoistLiftFeet": 50,
    "hoistLiftInch": 8,
    "hoistLift": 50.8,
    "hoistSpeed": "18 fpm",
    "hoistControlType": "Two step inverter control",
    "hoistHP": 14,
    "hoistReeving": "3 part double",
    "hoistBrakes": "Inverter Duty Motor Brake",
    "hoistTrolleySpeed": "80 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Wormgear Drive",
    "hoistWheelDiameter": 6,
    "hoistWeight": 2000,
    "hoistElectrificationSideApproach": 47.625,
    "hoistSideApproach": 35.375,
    "hoistHeadroomC": 36,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX20210008P16FADO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "3/4",
    "hoistCapacity": 0.75,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$10,147.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "40/6.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "2.4/0.33",
    "hoistReeving": "2 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 440,
    "hoistElectrificationSideApproach": 21.57,
    "hoistSideApproach": 21.57,
    "hoistHeadroomC": 12.6,
    "hoistAboveHeadroomC": 10.55
  },
  {
    "hoistModel": "SX20210008P16FALO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "3/4",
    "hoistCapacity": 0.75,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$8,121.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "40/6.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "2.4/0.33",
    "hoistReeving": "2 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 463,
    "hoistElectrificationSideApproach": 6.53,
    "hoistSideApproach": 6.54,
    "hoistHeadroomC": 17.47,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX20210008P16FANO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "3/4",
    "hoistCapacity": 0.75,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$8,583.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "40/6.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "2.4/0.33",
    "hoistReeving": "2 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 500,
    "hoistElectrificationSideApproach": 7.2,
    "hoistSideApproach": 12.72,
    "hoistHeadroomC": 29.13,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX20410020P15FCDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 2,
    "hoistCapacity": 2,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$11,584.00",
    "hoistLiftFeet": 31,
    "hoistLiftInch": 2,
    "hoistLift": 31.2,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "3/0.4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 470,
    "hoistElectrificationSideApproach": 21.2,
    "hoistSideApproach": 21.16,
    "hoistHeadroomC": 10.63,
    "hoistAboveHeadroomC": 10.43
  },
  {
    "hoistModel": "SX20410020P15FALO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 2,
    "hoistCapacity": 2,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$8,332.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "3/0.4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 474,
    "hoistElectrificationSideApproach": 6.53,
    "hoistSideApproach": 6.54,
    "hoistHeadroomC": 15.51,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX20410020P15FCLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 2,
    "hoistCapacity": 2,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$9,541.00",
    "hoistLiftFeet": 31,
    "hoistLiftInch": 2,
    "hoistLift": 31.2,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "3/0.4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 518,
    "hoistElectrificationSideApproach": 6.53,
    "hoistSideApproach": 9.09,
    "hoistHeadroomC": 15.51,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX40210020P36FBLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 2,
    "hoistCapacity": 2,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$12,287.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "40/6.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "5.8/0.9",
    "hoistReeving": "2 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".333 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 834,
    "hoistElectrificationSideApproach": 10.16,
    "hoistSideApproach": 13.62,
    "hoistHeadroomC": 20.44,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX30410032P25FADO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 3,
    "hoistCapacity": 3,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$12,927.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "5.8/0.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".4 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 450,
    "hoistElectrificationSideApproach": 21.2,
    "hoistSideApproach": 21.16,
    "hoistHeadroomC": 10.63,
    "hoistAboveHeadroomC": 10.43
  },
  {
    "hoistModel": "SX30410032P25FCDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 3,
    "hoistCapacity": 3,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$14,041.00",
    "hoistLiftFeet": 31,
    "hoistLiftInch": 2,
    "hoistLift": 31.2,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "5.8/0.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 6,
    "hoistWeight": 470,
    "hoistElectrificationSideApproach": 21.2,
    "hoistSideApproach": 21.16,
    "hoistHeadroomC": 10.63,
    "hoistAboveHeadroomC": 10.43
  },
  {
    "hoistModel": "SX30410032P25FALO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 3,
    "hoistCapacity": 3,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$10,987.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "5.8/0.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 474,
    "hoistElectrificationSideApproach": 6.53,
    "hoistSideApproach": 6.54,
    "hoistHeadroomC": 15.51,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX30410032P25FCLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 3,
    "hoistCapacity": 3,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$11,936.00",
    "hoistLiftFeet": 31,
    "hoistLiftInch": 2,
    "hoistLift": 31.2,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "5.8/0.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 515,
    "hoistElectrificationSideApproach": 6.53,
    "hoistSideApproach": 9.09,
    "hoistHeadroomC": 15.51,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX40410050P35FBDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$15,552.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 772,
    "hoistElectrificationSideApproach": 25.31,
    "hoistSideApproach": 25.31,
    "hoistHeadroomC": 15.75,
    "hoistAboveHeadroomC": 11.97
  },
  {
    "hoistModel": "SX40410050P35FCDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$16,235.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 794,
    "hoistElectrificationSideApproach": 25.31,
    "hoistSideApproach": 25.31,
    "hoistHeadroomC": 15.75,
    "hoistAboveHeadroomC": 11.97
  },
  {
    "hoistModel": "SX40410050P35FDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$17,523.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 827,
    "hoistElectrificationSideApproach": 25.31,
    "hoistSideApproach": 25.31,
    "hoistHeadroomC": 15.75,
    "hoistAboveHeadroomC": 11.97
  },
  {
    "hoistModel": "SX40410050P35FEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$18,034.00",
    "hoistLiftFeet": 49,
    "hoistLiftInch": 2,
    "hoistLift": 49.2,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 871,
    "hoistElectrificationSideApproach": 25.31,
    "hoistSideApproach": 25.31,
    "hoistHeadroomC": 15.75,
    "hoistAboveHeadroomC": 11.97
  },
  {
    "hoistModel": "SX50410050P56FFDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$25,136.00",
    "hoistLiftFeet": 52,
    "hoistLiftInch": 5,
    "hoistLift": 52.5,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "12/1.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1642,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 18.3,
    "hoistAboveHeadroomC": 16.73
  },
  {
    "hoistModel": "SX50210050P55FDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$21,550.00",
    "hoistLiftFeet": 59,
    "hoistLiftInch": 0,
    "hoistLift": 59,
    "hoistSpeed": "40/6.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "2 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1477,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 20.5,
    "hoistAboveHeadroomC": 16.77
  },
  {
    "hoistModel": "SX40410050P35FBLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$13,261.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 845,
    "hoistElectrificationSideApproach": 13.31,
    "hoistSideApproach": 13.62,
    "hoistHeadroomC": 19.07,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX40410050P35FBNO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$13,810.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1036,
    "hoistElectrificationSideApproach": 9.96,
    "hoistSideApproach": 9.96,
    "hoistHeadroomC": 39.2,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX40410050P35FCLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$13,954.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".3 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 889,
    "hoistElectrificationSideApproach": 13.31,
    "hoistSideApproach": 17.56,
    "hoistHeadroomC": 19.07,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX40410050P35FDLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$14,833.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 933,
    "hoistElectrificationSideApproach": 13.31,
    "hoistSideApproach": 21.5,
    "hoistHeadroomC": 19.07,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX40410050P35FENO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$17,428.00",
    "hoistLiftFeet": 49,
    "hoistLiftInch": 2,
    "hoistLift": 49.2,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "7.2/1.2",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1191,
    "hoistElectrificationSideApproach": 18.23,
    "hoistSideApproach": 18.23,
    "hoistHeadroomC": 39.2,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50210050P55FDLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 5,
    "hoistCapacity": 5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$19,133.00",
    "hoistLiftFeet": 59,
    "hoistLiftInch": 0,
    "hoistLift": 59,
    "hoistSpeed": "40/6.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "2 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Conical Rotor",
    "hoistWheelDiameter": 4,
    "hoistWeight": 878,
    "hoistElectrificationSideApproach": 16.18,
    "hoistSideApproach": 25.47,
    "hoistHeadroomC": 24.64,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410080P56FDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "7-1/2",
    "hoistCapacity": 7.5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$22,540.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "12/1.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1477,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 18.3,
    "hoistAboveHeadroomC": 16.73
  },
  {
    "hoistModel": "SX50410080P56FEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "7-1/2",
    "hoistCapacity": 7.5,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$24,006.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "12/1.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1543,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 18.3,
    "hoistAboveHeadroomC": 16.73
  },
  {
    "hoistModel": "SX50410080P56FDLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "7-1/2",
    "hoistCapacity": 7.5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$19,417.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "12/1.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1345,
    "hoistElectrificationSideApproach": 19.25,
    "hoistSideApproach": 25.47,
    "hoistHeadroomC": 25.06,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410080P56FELO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "7-1/2",
    "hoistCapacity": 7.5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$20,941.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "12/1.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1455,
    "hoistElectrificationSideApproach": 19.25,
    "hoistSideApproach": 30.19,
    "hoistHeadroomC": 25.06,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410080P56FFLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "7-1/2",
    "hoistCapacity": 7.5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$22,844.00",
    "hoistLiftFeet": 52,
    "hoistLiftInch": 5,
    "hoistLift": 52.5,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "12/1.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1565,
    "hoistElectrificationSideApproach": 19.25,
    "hoistSideApproach": 37.25,
    "hoistHeadroomC": 25.06,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410080P56FGNO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": "7-1/2",
    "hoistCapacity": 7.5,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$25,909.00",
    "hoistLiftFeet": 65,
    "hoistLiftInch": 7,
    "hoistLift": 65.7,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "12/1.9",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 2183,
    "hoistElectrificationSideApproach": 20.235,
    "hoistSideApproach": 20.235,
    "hoistHeadroomC": 40.31,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410100P55FDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$22,540.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1477,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 18.3,
    "hoistAboveHeadroomC": 16.73
  },
  {
    "hoistModel": "SX50410100P55FEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$24,006.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1543,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 18.3,
    "hoistAboveHeadroomC": 16.73
  },
  {
    "hoistModel": "SX50410100P55FFDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$25,891.00",
    "hoistLiftFeet": 52,
    "hoistLiftInch": 5,
    "hoistLift": 52.5,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1642,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 18.3,
    "hoistAboveHeadroomC": 16.73
  },
  {
    "hoistModel": "SX50410100P55FGDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$26,842.00",
    "hoistLiftFeet": 65,
    "hoistLiftInch": 7,
    "hoistLift": 65.7,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1731,
    "hoistElectrificationSideApproach": 27.09,
    "hoistSideApproach": 27.09,
    "hoistHeadroomC": 18.3,
    "hoistAboveHeadroomC": 16.73
  },
  {
    "hoistModel": "SX50410100P55FDLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$19,417.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1345,
    "hoistElectrificationSideApproach": 19.25,
    "hoistSideApproach": 25.47,
    "hoistHeadroomC": 25.06,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410100P55FDNO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$20,788.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1742,
    "hoistElectrificationSideApproach": 19.685,
    "hoistSideApproach": 19.685,
    "hoistHeadroomC": 40.31,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410100P55FELO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$20,941.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1455,
    "hoistElectrificationSideApproach": 19.25,
    "hoistSideApproach": 30.19,
    "hoistHeadroomC": 25.06,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410100P55FFLO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$22,884.00",
    "hoistLiftFeet": 52,
    "hoistLiftInch": 5,
    "hoistLift": 52.5,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1565,
    "hoistElectrificationSideApproach": 19.25,
    "hoistSideApproach": 37.25,
    "hoistHeadroomC": 25.06,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50410100P55FGNO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 10,
    "hoistCapacity": 10,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$25,909.00",
    "hoistLiftFeet": 65,
    "hoistLiftInch": 7,
    "hoistLift": 65.7,
    "hoistSpeed": "20/3.3 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": ".6 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 2183,
    "hoistElectrificationSideApproach": 20.235,
    "hoistSideApproach": 20.235,
    "hoistHeadroomC": 40.31,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50610150P55FDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$33,925.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "13/2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1676,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX50610150P55FEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$35,314.00",
    "hoistLiftFeet": 26,
    "hoistLiftInch": 2,
    "hoistLift": 26.2,
    "hoistSpeed": "13/2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1764,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX50610150P55FFDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$36,982.00",
    "hoistLiftFeet": 32,
    "hoistLiftInch": 9,
    "hoistLift": 32.9,
    "hoistSpeed": "13/2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1.5 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1874,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX50610150P55FGDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$38,426.00",
    "hoistLiftFeet": 42,
    "hoistLiftInch": 7,
    "hoistLift": 42.7,
    "hoistSpeed": "13/2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1.5 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 2017,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX60410160P65EFDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$49,326.00",
    "hoistLiftFeet": 59,
    "hoistLiftInch": 0,
    "hoistLift": 59,
    "hoistSpeed": "16/2.4 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3307,
    "hoistElectrificationSideApproach": 36.22,
    "hoistSideApproach": 35.67,
    "hoistHeadroomC": 35.25,
    "hoistAboveHeadroomC": 24.84
  },
  {
    "hoistModel": "SX50610150P55FDNO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$33,128.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "13/2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 2271,
    "hoistElectrificationSideApproach": 23.15,
    "hoistSideApproach": 23.15,
    "hoistHeadroomC": 55.63,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50610150P55FENO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$34,154.00",
    "hoistLiftFeet": 26,
    "hoistLiftInch": 2,
    "hoistLift": 26.2,
    "hoistSpeed": "13/2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 2315,
    "hoistElectrificationSideApproach": 23.15,
    "hoistSideApproach": 23.15,
    "hoistHeadroomC": 55.63,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX50610150P55FFNO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$35,172.00",
    "hoistLiftFeet": 32,
    "hoistLiftInch": 9,
    "hoistLift": 32.9,
    "hoistSpeed": "13/2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 2513,
    "hoistElectrificationSideApproach": 25.515,
    "hoistSideApproach": 25.515,
    "hoistHeadroomC": 55.63,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX60410160P65EFNO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 15,
    "hoistCapacity": 15,
    "hoistType": "Monorail Hoist",
    "hoistPrice": "$57,471.00",
    "hoistLiftFeet": 59,
    "hoistLiftInch": 0,
    "hoistLift": 59,
    "hoistSpeed": "16/2.4 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 5732,
    "hoistElectrificationSideApproach": 33.185,
    "hoistSideApproach": 33.185,
    "hoistHeadroomC": 69.35,
    "hoistAboveHeadroomC": ""
  },
  {
    "hoistModel": "SX60410200P64EJDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$59,067.00",
    "hoistLiftFeet": 131,
    "hoistLiftInch": 2,
    "hoistLift": 131.2,
    "hoistSpeed": "16/2.4 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 4409,
    "hoistElectrificationSideApproach": 36.22,
    "hoistSideApproach": 35.67,
    "hoistHeadroomC": 35.25,
    "hoistAboveHeadroomC": 24.84
  },
  {
    "hoistModel": "SX50810200P54FDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$35,612.00",
    "hoistLiftFeet": 14,
    "hoistLiftInch": 9,
    "hoistLift": 14.9,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "8 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1698,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX50810200P54FEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$37,078.00",
    "hoistLiftFeet": 19,
    "hoistLiftInch": 8,
    "hoistLift": 19.8,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "8 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1797,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX50810200P54FFDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$38,392.00",
    "hoistLiftFeet": 26,
    "hoistLiftInch": 2,
    "hoistLift": 26.2,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "8 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 1907,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX50810200P54FGDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$39,999.00",
    "hoistLiftFeet": 32,
    "hoistLiftInch": 9,
    "hoistLift": 32.9,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "14.8/2.1",
    "hoistReeving": "8 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 2039,
    "hoistElectrificationSideApproach": 27.68,
    "hoistSideApproach": 27.68,
    "hoistHeadroomC": 31.1,
    "hoistAboveHeadroomC": 16.93
  },
  {
    "hoistModel": "SX60410200P64EEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$47,312.00",
    "hoistLiftFeet": 45,
    "hoistLiftInch": 11,
    "hoistLift": 45.11,
    "hoistSpeed": "16/2.4 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3109,
    "hoistElectrificationSideApproach": 36.22,
    "hoistSideApproach": 35.67,
    "hoistHeadroomC": 35.25,
    "hoistAboveHeadroomC": 24.84
  },
  {
    "hoistModel": "SX60410200P64EFDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 20,
    "hoistCapacity": 20,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$49,326.00",
    "hoistLiftFeet": 59,
    "hoistLiftInch": 0,
    "hoistLift": 59,
    "hoistSpeed": "16/2.4 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "4 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3307,
    "hoistElectrificationSideApproach": 36.22,
    "hoistSideApproach": 35.67,
    "hoistHeadroomC": 35.25,
    "hoistAboveHeadroomC": 24.84
  },
  {
    "hoistModel": "SX60610250P65EDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 25,
    "hoistCapacity": 25,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$51,237.00",
    "hoistLiftFeet": 22,
    "hoistLiftInch": 11,
    "hoistLift": 22.11,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3638,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60610250P65EEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 25,
    "hoistCapacity": 25,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$53,559.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3748,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60610250P65EFDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 25,
    "hoistCapacity": 25,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$54,895.00",
    "hoistLiftFeet": 39,
    "hoistLiftInch": 4,
    "hoistLift": 39.4,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3968,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60610250P65EHDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 25,
    "hoistCapacity": 25,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$60,424.00",
    "hoistLiftFeet": 65,
    "hoistLiftInch": 7,
    "hoistLift": 65.7,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 4872,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60610300P64EDDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 30,
    "hoistCapacity": 30,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$51,237.00",
    "hoistLiftFeet": 22,
    "hoistLiftInch": 11,
    "hoistLift": 22.11,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3638,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60610300P64EEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 30,
    "hoistCapacity": 30,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$53,559.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "10/1.6 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "6 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3748,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60810320P65EGDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 30,
    "hoistCapacity": 30,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$65,172.00",
    "hoistLiftFeet": 37,
    "hoistLiftInch": 8,
    "hoistLift": 37.8,
    "hoistSpeed": "8/1.2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "8 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1.5 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 4432,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60810400P64EEDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 40,
    "hoistCapacity": 40,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$60,251.00",
    "hoistLiftFeet": 22,
    "hoistLiftInch": 11,
    "hoistLift": 22.11,
    "hoistSpeed": "8/1.2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "8 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "1.5 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 3925,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX60810400P64EGDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 40,
    "hoistCapacity": 40,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$65,172.00",
    "hoistLiftFeet": 37,
    "hoistLiftInch": 8,
    "hoistLift": 37.8,
    "hoistSpeed": "8/1.2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "24.1/4",
    "hoistReeving": "8 part single",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 4,
    "hoistWeight": 4432,
    "hoistElectrificationSideApproach": 36.93,
    "hoistSideApproach": 36.38,
    "hoistHeadroomC": 37.5,
    "hoistAboveHeadroomC": 27.28
  },
  {
    "hoistModel": "SX70820630P65EHDO",
    "hoistManufacturer": "rm",
    "hoistCapacityNumber": 60,
    "hoistCapacity": 60,
    "hoistType": "Double Girder Trolley Hoist",
    "hoistPrice": "$92,196.00",
    "hoistLiftFeet": 29,
    "hoistLiftInch": 6,
    "hoistLift": 29.6,
    "hoistSpeed": "8/1.2 fpm",
    "hoistControlType": "Two speed",
    "hoistHP": "48.2/8",
    "hoistReeving": "8 part double",
    "hoistBrakes": "Regenerative & Magnetic Disc",
    "hoistTrolleySpeed": "65 fpm",
    "hoistControlType2": "Two step inverter control",
    "hoistTrolleyHP": "2 @ 3600",
    "hoistTrolleyBrakes": "Magnetic Disc",
    "hoistWheelDiameter": 10,
    "hoistWeight": 7959,
    "hoistElectrificationSideApproach": 37.37,
    "hoistSideApproach": 39.18,
    "hoistHeadroomC": 55.5,
    "hoistAboveHeadroomC": 29.72
  }
]
  export default hoists;